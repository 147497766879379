import React, { useState, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import Textinput from "../components/ui"
import { usePostalJp } from "use-postal-jp"

const Form = () => {
  const methods = useFormContext()
  const [value, setValue] = useState("")
  const {
    address,
    error,
    pending,
    sanitizedCode,
    setPostalCode,
  } = usePostalJp()
  useEffect(() => {
    if (document.URL.match("101563")) {
      methods.setValue("store", "東銀座")
      methods.setValue("sendmail", "higashiginza@nikkei-ns.com")
    } else if (document.URL.match("101806")) {
      methods.setValue("store", "銀座")
      methods.setValue("sendmail", "nsn-ginza@nikkei-ns.com")
    } else if (document.URL.match("102187")) {
      methods.setValue("store", "明大前")
      methods.setValue("sendmail", "meidaimae@nikkei-ns.com")
    } else if (document.URL.match("102161")) {
      methods.setValue("store", "三軒茶屋")
      methods.setValue("sendmail", "sangenjaya@nikkei-ns.com")
    } else if (document.URL.match("102129")) {
      methods.setValue("store", "品川")
      methods.setValue("sendmail", "shinagawa@nikkei-ns.com")
    } else if (document.URL.match("101547")) {
      methods.setValue("store", "浜町")
      methods.setValue("sendmail", "hamacho@nikkei-ns.com")
    } else if (document.URL.match("102226")) {
      methods.setValue("store", "世田谷")
      methods.setValue("sendmail", "setagaya@nikkei-ns.com")
    } else if (document.URL.match("102145")) {
      methods.setValue("store", "都立大学")
      methods.setValue("sendmail", "toritsudaigaku@nikkei-ns.com")
    } else if (document.URL.match("100567")) {
      methods.setValue("store", "本郷御茶ノ水")
      methods.setValue("sendmail", "hongo@nikkei-ns.com")
    } else if (document.URL.match("11")) {
      methods.setValue("store", "テスト")
      methods.setValue("sendmail", "nao3546@gmail.com")
    }
    setPostalCode(value)
  }, [value, setPostalCode])
  const Plans = [
    { id: 1, value: "無料トライアル" },
    { id: 2, value: "資料請求" },
    { id: 3, value: "ご契約（6ヶ月）" },
    { id: 4, value: "ご契約（12ヶ月）" },
  ]

  const Plan = methods.watch("plan")
  const ChangeZip = (e: any) => {
    setValue(e)
    methods.setValue("pref", address.prefecture, {
      shouldDirty: true,
      shouldValidate: true,
    })
    {
      address.address1 &&
        methods.setValue("city", address.address1 + address.address2, {
          shouldDirty: true,
          shouldValidate: true,
        })
    }
  }
  return (
    <>
      <input type="hidden" name="store" ref={methods.register} />
      <input type="hidden" name="sendmail" ref={methods.register} />
      <div className="w-full shadow-2xl p-8" id="form1" key="form1">
        <div className="flex flex-col -mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="uppercase tracking-wide text-gray-700 text-sm sm:text-base font-bold mb-2 "
              htmlFor="plan"
            >
              お申込内容
              <span
                className={`ml-1 ${
                  Plan && !methods.errors.plan
                    ? "text-green-500 border border-green-500"
                    : "text-red-500 border border-red-500"
                }`}
              >
                {Plan && !methods.errors.plan ? "OK" : "必須"}
              </span>
              <div className="flex flex-col sm:flex-row">
                {Plans.map((d) => (
                  <div key={d.id}>
                    <input
                      type="radio"
                      name="plan"
                      value={d.value}
                      className="m-2 form-radio h-5 w-5 text-indigo-600 align-middle "
                      ref={methods.register}
                    />
                    <span
                      className="align-middle text-gray-700"
                      onClick={() => methods.setValue("plan", d.value)}
                    >
                      {d.value}
                    </span>
                  </div>
                ))}
              </div>
            </label>
            <p className="text-red-500 text-xs italic">
              {methods.errors.plan?.message}
            </p>
            {Plan === "無料トライアル" && (
              <div className="w-full sm:w-1/2 px-3 mb-6 md:mb-0">
                <Textinput
                  label="ライセンス数"
                  type="number"
                  name="trial"
                  auto=""
                  required={true}
                  footer={methods.errors.trial?.message}
                />
                <p className="text-xs">
                  ※トライアルをご希望になる想定の人数をご入力ください
                </p>
              </div>
            )}
          </div>

          <div className="flex flex-col">
            <div className="w-full px-3 mb-6 md:mb-0">
              <Textinput
                label="貴社名"
                type="text"
                name="name1"
                auto="organization"
                required={true}
                footer={methods.errors.name1?.message}
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <Textinput
                label="部署・役職"
                type="text"
                name="name2"
                auto="organization-title"
                required={true}
                footer={methods.errors.name2?.message}
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <Textinput
                label="お申込者様氏名"
                type="text"
                name="name3"
                auto="name"
                required={true}
                footer={methods.errors.name3?.message}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row -mx-3 mb-6">
          <div className="w-full sm:w-1/4 text-center mb-6">貴社住所</div>
          <div className="w-full flex flex-col">
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <Textinput
                label="郵便番号(住所自動入力)"
                type="text"
                name="zip"
                auto="postal-code"
                required={true}
                onBlur={(e: any) => ChangeZip(e.target.value)}
                onChange={(e: any) => ChangeZip(e.target.value)}
                footer={methods.errors.zip?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <Textinput
                label="都道府県"
                type="text"
                name="pref"
                auto="address-level1"
                required={true}
                footer={methods.errors.pref?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <Textinput
                label="市区町村"
                type="text"
                name="city"
                auto="address-level2"
                required={true}
                footer={methods.errors.city?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <Textinput
                label="番地"
                type="text"
                name="addr"
                place="例）１−１"
                auto="address-level3"
                required={true}
                footer={methods.errors.addr?.message}
              />
            </div>

            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <Textinput
                label="建物名・部屋番号"
                type="text"
                name="building"
                place="例）日経ビル　１０１"
                auto="address-level4"
                required={false}
                footer={methods.errors.building?.message}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col -mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mb-0">
              <Textinput
                label="メールアドレス"
                type="email"
                name="mail"
                auto="email"
                required={true}
                footer={methods.errors.mail?.message}
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <Textinput
                label="お電話番号"
                type="tel"
                name="tel"
                auto="tel"
                required={true}
                footer={methods.errors.tel?.message}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <Textinput
              label="連絡事項（任意）"
              type="textarea"
              name="message"
              required={false}
              footer={methods.errors.message?.message}
            />

            <p className="text-gray-600 text-xs italic">
              ご不明点やご要望がございましたらご入力ください
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Form
