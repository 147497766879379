import React from "react"
import Lottie from "lottie-react-web"
import animation from "../images/14331-error.json"

const Error = () => {
  return (
    <div className="w-full" id="form2">
      <div className="text-base sm:text-2xl text-center block  tracking-wide text-gray-700 py-4">
        申し訳ありません。エラーが発生しました。
        <br />
        大変お手数ですが、再度のお申し込みをお願い致します。
      </div>
      <div className="">
        <Lottie
          options={{ animationData: animation, loop: false }}
          height={400}
        />
      </div>
    </div>
  )
}

export default Error
