import React from "react"
import { useFormContext } from "react-hook-form"

const Conf = () => {
  const methods = useFormContext()
  const values = methods.getValues()

  return (
    <div className="w-full" id="form2">
      <div className="text-lg sm:text-2xl text-center block  tracking-wide text-gray-700 py-4">
        入力内容をご確認ください
      </div>

      <div className="rounded-md shadow-2xl p-8">
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          お申込内容
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.plan}
        </h5>
        {values.plan === "無料トライアル" && (
          <>
            <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
              ライセンス数
            </label>
            <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
              {values.trial}
            </h5>
          </>
        )}

        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          貴社名
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.name1}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          部署・役職
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.name2}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          お申込者様氏名
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.name3}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          ご住所
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          〒{values.zip}
          <br />
          {values.pref}
          {values.city}
          {values.addr}
          <br />
          {values.building}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          メールアドレス
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.mail}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          電話番号
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.tel}
        </h5>
        {values.message && (
          <>
            <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
              連絡事項
            </label>
            <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
              {values.message}
            </h5>
          </>
        )}
      </div>

      <div className="align-center bg-yellow-500 rounded-md shadow-2xl p-8 m-4">
        <ul className="list-none">
          {values.plan === "無料トライアル" && (
            <li className="py-2">
              本申込みは無料トライアルであり、ご契約手続きをされない限り、お支払いは発生しません。
            </li>
          )}
          {values.store === "浜町" ? (
            <li className="py-2">
              申込後にニュースサービス日経浜町から詳細をお伺いさせていただきます。
            </li>
          ) : values.store === "本郷御茶ノ水" ? (
            <li className="py-2">
              申込後にニュースサービス日経本郷御茶ノ水から詳細をお伺いさせていただきます。
            </li>
          ) : (
            <li className="py-2">
              申込後に総代理店のニュースサービス日経日本橋店から詳細をお伺いさせていただきます。
            </li>
          )}

          <li className="py-2">
            「ニュースサービス日経」は日本経済新聞販売店の呼称です。
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Conf
