import React from "react"
import Lottie from "lottie-react-web"
import animation from "../images/27572-success-animation.json"

const Success = () => {
  return (
    <div className="w-full" id="form2">
      <div className="text-lg sm:text-2xl text-center block  tracking-wide text-gray-700 py-4">
        お申し込みありがとうござました
      </div>
      <div className="">
        <Lottie
          options={{ animationData: animation, loop: false }}
          height={400}
        />
      </div>
    </div>
  )
}

export default Success
