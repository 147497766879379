import React from "react"
import { useFormContext } from "react-hook-form"

type InputData = {
  label: string
  type: string
  name: string
  footer: string
  value?: string
  place?: string
  defaultValues?: string
  onChange?: any
  onBlur?: any
  auto?: string
  required: boolean
}
const Textform: React.FC<InputData> = (props) => {
  const {
    label,
    type,
    name,
    footer,
    value,
    place,
    defaultValues,
    onChange,
    onBlur,
    auto,
    required,
  } = props
  const methods = useFormContext()

  const watch = methods.watch(name)
  const trans = (e: any) => {
    const str = e.target.value
    methods.setValue(
      name,
      str.replace(
        /[Ａ-Ｚａ-ｚ０-９]/g,
        function (s: any) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
        },
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      )
    )
  }

  let elem = (
    <input
      className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
        watch && !methods.errors.name ? "border-green-500" : "border-0"
      }`}
      id={name}
      name={name}
      type={type}
      ref={methods.register}
      onChange={onChange}
      onBlur={trans}
      value={value}
      defaultValue={defaultValues}
      placeholder={place}
      autoComplete={auto}
    />
  )
  if (name == "zip") {
    elem = (
      <input
        className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
          watch && !methods.errors.name ? "border-green-500" : "border-0"
        }`}
        id={name}
        name={name}
        type={type}
        ref={methods.register}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValues}
        placeholder=""
        autoComplete={auto}
      />
    )
  }
  if (type === "textarea") {
    elem = (
      <textarea
        className={`text-lg sm:text-xl no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none ${
          watch && !methods.errors.name ? "border-green-500" : "border-0"
        }`}
        id={name}
        name={name}
        ref={methods.register}
        onChange={onChange}
        onBlur={trans}
        value={value}
        defaultValue={defaultValues}
        placeholder=""
        autoComplete={auto}
      ></textarea>
    )
  }

  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-sm sm:text-base font-bold mb-2"
        htmlFor={name}
      >
        {label}
        {required && (
          <span
            className={`ml-1 ${
              watch && !methods.errors.name
                ? "text-green-500 border border-green-500"
                : "text-red-500 border border-red-500"
            }`}
          >
            {watch && !methods.errors.name ? "OK" : "必須"}
          </span>
        )}
      </label>
      {elem}
      {footer && <p className="text-red-500 text-xs italic mb-2">{footer}</p>}
    </>
  )
}
export default Textform
